import React from "react";
import { LandingPageTemplate } from "../ui/templates/LandingPageTemplate";
import { SeoShareFit } from "../components/SeoShareFit";

const ShareFitPage = () => {
  return(
      [
          <SeoShareFit />,
          <LandingPageTemplate />
      ]
  );
};

export default ShareFitPage;
